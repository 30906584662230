import React, { useState } from "react";
import styles from "./home.module.scss";
import "../../assets/css/bootstrap.css";
import ben from "../../assets/img/ben.7656dd36.png";
import claire from "../../assets/img/claire.bfdbc161.png";
import iwan from "../../assets/img/iwan.70e1c572.png";
import judith from "../../assets/img/judith.8d0417f0.png";
import lori from "../../assets/img/lori.07099057.png";
import mali from "../../assets/img/mali.a8248c71.png";
import mi from "../../assets/img/mi.68a0f720.png";
import nim from "../../assets/img/nim.d6bbfb63.png";
import san from "../../assets/img/san.3b86301b.png";
import sanjid from "../../assets/img/sanjid.2d7a907f.png";
import steph from "../../assets/img/steph.68f47994.png";
import zak from "../../assets/img/zak.be2e6aeb.png";
import lorenzo from "../../assets/img/lorenzo.png";
import james from "../../assets/img/james.png";
import photo_a from "../../assets/img/photo_a.png";
import photo_b from "../../assets/img/photo_b.png";
import photo_c from "../../assets/img/photo_c.png";
import photo_d from "../../assets/img/photo_d.png";
import logo from "../../assets/img/logo.png";
import ModalComponent from "../../components/Popup";
import validator from "validator";

const Home = () => {
  const [formData, setFormData] = useState({});

  const handleInputChange = (event) => {
    if (event.target.value === "" || !validator.isEmail(event.target.value)) {
      setFormData({ sender: "PropReg email service" });
    } else {
      const { value } = event.target;
      setFormData({ email: value, sender: "PropReg email service" });
    }
  };

  const handleInputChangeTwo = (event) => {
    if (event.target.value === "" || !validator.isEmail(event.target.value)) {
      setFormData({ sender: "PropReg email service" });
    } else {
      const { value } = event.target;
      setFormData({ email: value, sender: "PropReg email service" });
    }
  };

  const imgData = [
    { name: ben },
    { name: claire },
    { name: iwan },
    { name: judith },
    { name: lori },
    { name: mali },
    { name: mi },
    { name: nim },
    { name: san },
    { name: sanjid },
    { name: steph },
    { name: zak },
  ];

  return (
    <div id="app">
      <div>
        <div
          className={`${styles["site-content"]} ${styles["bg-white"]} ${styles["overflow-hidden"]}`}
          data-testid="site-content"
        >
          <section
            id="1"
            className={`${styles["pt-8"]} ${styles["overflow-hidden"]} ${styles["sm:pt-12"]} ${styles["lg:relative"]} ${styles["lg:py-48"]}`}
            sectionname="hero"
            sectioncomponent="Hero"
            sortorder="0"
          >
            <div
              className={`${styles["flex-right"]} ${styles["max-w-md"]} ${styles["px-4"]} ${styles["mx-auto"]} ${styles["sm:max-w-3xl"]} ${styles["sm:px-6"]} ${styles["lg:px-8"]} ${styles["lg:max-w-7xl"]} ${styles["lg:grid"]} ${styles["lg:grid-cols-2"]} ${styles["lg:gap-24"]}`}
            >
              <div
                className={`${styles["flex"]} ${styles["items-center"]} ${styles["space-x-2"]}`}
              >
                <img
                  className={`${styles["w-auto"]} ${styles["h-8"]}`}
                  src={logo}
                  alt="Code secure logo"
                />
              </div>
              <div
                className={`${styles["items-center"]} ${styles["space-x-2"]}`}
              >
                <a
                  className={`${styles["mixo-badge"]}`}
                  href="#"
                  target="_blank"
                  rel="noreferrer"
                  data-v-2391dbcb=""
                >
                  <b>Prototype</b>
                </a>
              </div>
            </div>
            <div
              className={`${styles["max-w-md"]} ${styles["px-4"]} ${styles["mx-auto"]} ${styles["sm:max-w-3xl"]} ${styles["sm:px-6"]} ${styles["lg:px-8"]} ${styles["lg:max-w-7xl"]} ${styles["lg:grid"]} ${styles["lg:grid-cols-2"]} ${styles["lg:gap-24"]}`}
            >
              <div>
                <div className={`${styles["mt-14"]}`}>
                  <div className={`${styles["mt-6"]} ${styles["sm:max-w-xl"]}`}>
                    <h1
                      className={`${styles["text-4xl"]} ${styles["font-black"]} ${styles["tracking-tight"]} ${styles["text-gray-900"]} ${styles["sm:text-6xl"]} ${styles["md:text-7xl"]}`}
                    >
                      PropReg: The Future of Property Ownership
                      <span className={`${styles["text-primary"]}`}>.</span>
                    </h1>
                    <h2
                      className={`${styles["mt-6"]} ${styles["text-lg"]} ${styles["text-gray-500"]} ${styles["sm:text-xl"]}`}
                    >
                      Revolutionize property transactions using advanced
                      blockchain and AI technology.
                    </h2>
                  </div>
                  <div className={`${styles["mt-10"]} ${styles["space-y-4"]}`}>
                    <ile-root id="ile-1" data-v-app="" hydrated="">
                      <form
                        className={`${styles["grid"]} ${styles["gap-2"]} ${styles["grid-cols-1"]} ${styles["sm:w-full"]} ${styles["sm:flex"]} ${styles["sm:items-center"]} ${styles["sm:flex-wrap"]} ${styles["mt-4"]} ${styles["sm:max-w-lg"]}`}
                      >
                        <label
                          htmlFor="hero-email"
                          className={`${styles["sr-only"]}`}
                        >
                          Email address
                        </label>
                        <input
                          type="text"
                          id="email"
                          name="email"
                          onChange={handleInputChange}
                          className={`${styles["block"]} ${styles["w-full"]} ${styles["px-5"]} ${styles["py-3"]} ${styles["text-base"]} ${styles["text-gray-900"]} ${styles["placeholder-gray-500"]} ${styles["border"]} ${styles["border-gray-300"]} ${styles["rounded-md"]} ${styles["shadow-sm"]} ${styles["focus:border-primary"]} ${styles["focus:ring-primary"]} ${styles["focus-visible:ring-primary"]} ${styles["flex-1"]}`}
                          required={true}
                          placeholder="Enter your email..."
                        ></input>
                        <div>
                          <ModalComponent
                            title="Thank you for subscribing!"
                            cancelButtonText="Cancel"
                            formData={formData}
                            actionButtonText="Submit"
                          />
                        </div>
                      </form>
                    </ile-root>
                    <script></script>
                  </div>
                  <div className={`${styles["mt-6"]}`}>
                    <div
                      className={`${styles["inline-flex"]} ${styles["items-center"]}`}
                    >
                      <img
                        src={lorenzo}
                        alt="Laurentiu Nae"
                        className={`${styles["object-cover"]} ${styles["inline-block"]} ${styles["mr-3"]} ${styles["border-2"]} ${styles["border-primary"]} ${styles["rounded-full"]} ${styles["sm:mr-2"]} ${styles["h-14"]} ${styles["w-14"]}`}
                      />
                      <div>
                        <p
                          className={`${styles["sm:pl-2.5"]} ${styles["text-base"]} ${styles["font-black"]} ${styles["tracking-tight"]} ${styles["text-gray-800"]} ${styles["sm:text-lg"]}`}
                        >
                          {" "}
                          “PropReg makes property transactions easy and secure!”{" "}
                        </p>
                        <p
                          className={`${styles["sm:pl-2.5"]} ${styles["text-sm"]} ${styles["sm:text-base"]} ${styles["font-bold"]} ${styles["text-gray-500"]}`}
                        >
                          Aw Esome
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`${styles["sm:mx-auto"]} ${styles["sm:max-w-3xl"]} ${styles["sm:px-6"]}`}
            >
              <div
                className={`${styles["py-12"]} ${styles["sm:relative"]} ${styles["sm:mt-16"]} ${styles["sm:py-16"]} ${styles["lg:absolute"]} ${styles["lg:inset-y-0"]} ${styles["lg:right-0"]} ${styles["lg:w-1/2"]}`}
              >
                <div className={`${styles["hidden"]} ${styles["sm:block"]}`}>
                  <div
                    className={`${styles["absolute"]} ${styles["inset-y-0"]} ${styles["w-screen"]} ${styles["left-1/2"]} ${styles["bg-gray-50"]} ${styles["rounded-l-3xl"]} ${styles["lg:left-80"]} ${styles["lg:right-0"]} ${styles["lg:w-full"]}`}
                  ></div>
                  <svg
                    className={`${styles["absolute"]} ${styles["-mr-3"]} ${styles["top-8"]} ${styles["right-1/2"]} ${styles["lg:m-0"]} ${styles["lg:left-0"]}`}
                    width="404"
                    height="392"
                    fill="none"
                    viewBox="0 0 404 392"
                  >
                    <defs>
                      <pattern
                        id="837c3e70-6c3a-44e6-8854-cc48c737b659"
                        x="0"
                        y="0"
                        width="20"
                        height="20"
                        patternUnits="userSpaceOnUse"
                      >
                        <rect
                          x="0"
                          y="0"
                          width="4"
                          height="4"
                          className={`${styles["text-gray-200"]}`}
                          fill="currentColor"
                        ></rect>
                      </pattern>
                    </defs>
                    <rect
                      width="404"
                      height="392"
                      fill="url(#ad119f34-7694-4c31-947f-5c9d249b21f3)"
                    ></rect>
                  </svg>
                </div>
                <div
                  className={`${styles["relative"]} ${styles["pl-4"]} ${styles["-mr:20"]} ${styles["sm:-mr-32"]} ${styles["md:-mr-16"]} ${styles["sm:mx-auto"]} ${styles["sm:max-w-3xl"]} ${styles["sm:px-0"]} ${styles["lg:h-full"]} ${styles["lg:max-w-none"]} ${styles["lg:flex"]} ${styles["lg:items-center"]} ${styles["xl:pl-12"]}`}
                >
                  <img
                    className={`${styles["w-full"]} ${styles["rounded-l-3xl"]} ${styles["lg:w-auto"]} ${styles["2xl:h-full"]} ${styles["2xl:max-w-none"]}`}
                    src={photo_a}
                    alt="CodeSecure"
                  />
                </div>
              </div>
            </div>
          </section>
          <section
            id="2"
            className={`${styles["relative"]} ${styles["pt-16"]} ${styles["pb-32"]} ${styles["overflow-hidden"]} ${styles["bg-white"]} ${styles["space-y-24"]}`}
            sectionname="features"
            sectioncomponent="Features"
            sortorder="1"
          >
            <div
              className={`${styles["lg:mx-auto"]} ${styles["lg:max-w-7xl"]} ${styles["lg:px-8"]} ${styles["lg:grid"]} ${styles["lg:grid-cols-2"]} ${styles["lg:grid-flow-col-dense"]} ${styles["lg:gap-6"]} ${styles["xl:gap-12"]} ${styles["2xl:gap-24"]}`}
            >
              <div
                className={`${styles["lg:col-start-2"]} ${styles["max-w-xl"]} ${styles["px-4"]} ${styles["mx-auto"]} ${styles["sm:px-6"]} ${styles["lg:py-32"]} ${styles["lg:max-w-none"]} ${styles["lg:mx-0"]} ${styles["lg:px-0"]} ${styles["space-y-6"]}`}
              >
                <div>
                  <h2
                    className={`${styles["text-4xl"]} ${styles["font-extrabold"]} ${styles["tracking-tight"]} ${styles["text-gray-900"]}`}
                  >
                    Faster and secure transactions
                    <span className={`${styles["text-primary"]}`}>.</span>
                  </h2>
                  <p
                    className={`${styles["mt-4"]} ${styles["text-lg"]} ${styles["leading-relaxed"]} ${styles["text-gray-500"]} ${styles["sm:text-xl"]}`}
                  >
                    With PropReg, property transactions are completed in record
                    time without compromising security. The backbone of the
                    system uses blockchain and AI technology to ensures that all
                    parties involved in the transaction are secure and
                    transparent.
                  </p>
                </div>
                <div></div>
              </div>
              <div
                className={`${styles["lg:col-start-1"]} ${styles["mt-12"]} ${styles["sm:mt-16"]} ${styles["lg:mt-0"]}`}
              >
                <div
                  className={`${styles["pr-4"]} ${styles["-sm:ml-48"]} ${styles["sm:pr-6"]} ${styles["md:-ml-16"]} ${styles["lg:px-0"]} ${styles["lg:m-0"]} ${styles["lg:relative"]} ${styles["lg:h-full"]} ${styles["lg:flex"]} ${styles["lg:items-center"]}`}
                >
                  <img
                    className={`${styles["lg:right-0"]} ${styles["w-full"]} ${styles["lg:absolute"]} ${styles["lg:w-auto"]} ${styles["lg:max-w-xl"]} ${styles["xl:max-w-2xl"]} ${styles["2xl:max-w-3xl"]} ${styles["rounded"]}`}
                    src={photo_b}
                    alt="Secure and robust outsourcing system"
                  />
                </div>
              </div>
            </div>
            <div
              className={`${styles["lg:mx-auto"]} ${styles["lg:max-w-7xl"]} ${styles["lg:px-8"]} ${styles["lg:grid"]} ${styles["lg:grid-cols-2"]} ${styles["lg:grid-flow-col-dense"]} ${styles["lg:gap-6"]} ${styles["xl:gap-12"]} ${styles["2xl:gap-24"]}`}
            >
              <div
                className={`${styles["max-w-xl"]} ${styles["px-4"]} ${styles["mx-auto"]} ${styles["sm:px-6"]} ${styles["lg:py-32"]} ${styles["lg:max-w-none"]} ${styles["lg:mx-0"]} ${styles["lg:px-0"]} ${styles["space-y-6"]}`}
              >
                <div>
                  <h2
                    className={`${styles["text-4xl"]} ${styles["font-extrabold"]} ${styles["tracking-tight"]} ${styles["text-gray-900"]}`}
                  >
                    Reliable property registry information
                    <span className={`${styles["text-primary"]}`}>.</span>
                  </h2>
                  <p
                    className={`${styles["mt-4"]} ${styles["text-lg"]} ${styles["leading-relaxed"]} ${styles["text-gray-500"]} ${styles["sm:text-xl"]}`}
                  >
                    Avoid disputes and fraud by using the most reliable property
                    registry information. Using advanced AI technology, the
                    platform ensures that all data is verified and accurate,
                    providing a trustworthy system for property ownership.
                  </p>
                </div>
                <div></div>
              </div>
              <div
                className={`${styles["mt-12"]} ${styles["sm:mt-16"]} ${styles["lg:mt-0"]}`}
              >
                <div
                  className={`${styles["pl-4"]} ${styles["sm:-mr-48"]} ${styles["sm:-mr-6"]} ${styles["sm:pl-6"]} ${styles["md:-mr-16"]} ${styles["lg:px-0"]} ${styles["lg:m-0"]} ${styles["lg:relative"]} ${styles["lg:h-full"]} ${styles["lg:flex"]} ${styles["lg:items-center"]}`}
                >
                  <img
                    className={`${styles["lg:left-0 w-full"]} ${styles["lg:absolute"]} ${styles["lg:w-auto"]} ${styles["lg:max-w-xl"]} ${styles["xl:max-w-2xl"]} ${styles["2xl:max-w-3xl"]} ${styles["rounded"]}`}
                    src={photo_c}
                    alt="Relaible code outsourcing"
                  />
                </div>
              </div>
            </div>
            <div
              className={`${styles["lg:mx-auto"]} ${styles["lg:max-w-7xl"]} ${styles["lg:px-8"]} ${styles["lg:grid"]} ${styles["lg:grid-cols-2"]} ${styles["lg:grid-flow-col-dense"]} ${styles["lg:gap-6"]} ${styles["xl:gap-12"]} ${styles["2xl:gap-24"]}`}
            >
              <div
                className={`${styles["lg:col-start-2"]} ${styles["max-w-xl"]} ${styles["px-4"]} ${styles["mx-auto"]} ${styles["sm:px-6"]} ${styles["lg:py-32"]} ${styles["lg:max-w-none"]} ${styles["lg:mx-0"]} ${styles["lg:px-0"]} ${styles["space-y-6"]}`}
              >
                <div>
                  <h2
                    className={`${styles["text-4xl"]} ${styles["font-extrabold"]} ${styles["tracking-tight"]} ${styles["text-gray-900"]}`}
                  >
                    Reduce transaction costs
                    <span className={`${styles["text-primary"]}`}>.</span>
                  </h2>
                  <p
                    className={`${styles["mt-4"]} ${styles["text-lg"]} ${styles["leading-relaxed"]} ${styles["text-gray-500"]} ${styles["sm:text-xl"]}`}
                  >
                    PropReg platform reduces transaction costs by eliminating
                    the need for third-party intermediaries. This leads to a
                    lower transaction cost, making it more accessible for
                    everyone to invest in property ownership.
                  </p>
                </div>
                <div></div>
              </div>
              <div
                className={`${styles["lg:col-start-1"]} ${styles["mt-12"]} ${styles["sm:mt-16"]} ${styles["lg:mt-0"]}`}
              >
                <div
                  className={`${styles["pr-4"]} ${styles["-sm:ml-48"]} ${styles["sm:pr-6"]} ${styles["md:-ml-16"]} ${styles["lg:px-0"]} ${styles["lg:m-0"]} ${styles["lg:relative"]} ${styles["lg:h-full"]} ${styles["lg:flex"]} ${styles["lg:items-center"]}`}
                >
                  <img
                    className={`${styles["lg:right-0"]} ${styles["w-full"]} ${styles["lg:absolute"]} ${styles["lg:w-auto"]} ${styles["lg:max-w-xl"]} ${styles["xl:max-w-2xl"]} ${styles["2xl:max-w-3xl"]} ${styles["rounded"]}`}
                    src={photo_d}
                    alt="Secure and robust outsourcing system"
                  />
                </div>
              </div>
            </div>
          </section>
          <section
            id="3"
            className={`${styles["py-12"]} ${styles["overflow-hidden"]} ${styles["bg-primary"]} ${styles["bg-opacity-80"]} ${styles["md:py-20"]}`}
            sectionname="testimonials"
            sectioncomponent="Testimonials"
            sortorder="2"
          >
            <div
              className={`${styles["relative"]} ${styles["px-4"]} ${styles["mx-auto"]} ${styles["max-w-7xl"]} ${styles["sm:px-6"]} ${styles["lg:px-8"]}`}
            >
              <svg
                className={`${styles["absolute"]} ${styles["transform"]} ${styles["top-full"]} ${styles["right-full"]} ${styles["translate-x-1/3"]} ${styles["-translate-y-1/4"]} ${styles["lg:translate-x-1/2"]} ${styles["xl:-translate-y-1/2"]} ${styles["rotate-3"]}`}
                width="404"
                height="404"
                fill="none"
                viewBox="0 0 404 404"
                role="img"
                aria-labelledby="svg-squares"
              >
                <title id="svg-squares">squares</title>
                <defs>
                  <pattern
                    id="ad119f34-7694-4c31-947f-5c9d249b21f3"
                    x="0"
                    y="0"
                    width="20"
                    height="20"
                    patternUnits="userSpaceOnUse"
                  >
                    <rect
                      x="0"
                      y="0"
                      width="4"
                      height="4"
                      className={`${styles["text-gray-200"]}`}
                      fill="currentColor"
                    ></rect>
                  </pattern>
                </defs>
                <rect
                  width="404"
                  height="404"
                  fill="url(#ad119f34-7694-4c31-947f-5c9d249b21f3)"
                ></rect>
              </svg>
              <div className={`${styles["relative"]}`}>
                <blockquote>
                  <div
                    className={`${styles["max-w-3xl"]} ${styles["mx-auto"]} ${styles["text-xl"]} ${styles["font-bold"]} ${styles["leading-7"]} ${styles["text-center"]} ${styles["text-white"]} ${styles["md:leading-10"]} ${styles["md:text-3xl"]} ${styles["text-shadow-sm"]}`}
                  >
                    <p>
                      {" "}
                      "I love the efficiency of the system. No more dealing with
                      intermediaries!"{" "}
                    </p>
                  </div>
                  <footer className={`${styles["mt-8"]}`}>
                    <div
                      className={`${styles["md:flex"]} ${styles["md:items-center"]} ${styles["md:justify-center"]}`}
                    >
                      <div className={`${styles["md:flex-shrink-0"]}`}>
                        <img
                          src={james}
                          className={`${styles["w-10"]} ${styles["h-10"]} ${styles["mx-auto"]} ${styles["border-2"]} ${styles["border-slate-200"]} ${styles["rounded-full"]} ${styles["shadow-sm"]} ${styles["object-cover"]}`}
                          alt="Testimonial"
                        />
                      </div>
                      <div
                        className={`${styles["mt-3"]} ${styles["text-center"]} ${styles["md:mt-0"]} ${styles["md:ml-3"]} ${styles["md:flex"]} ${styles["md:items-center"]} ${styles["text-shadow-sm"]}`}
                      >
                        <div
                          className={`${styles["text-lg"]} ${styles["font-medium"]} ${styles["text-white"]}`}
                        >
                          Bril Liant
                        </div>
                      </div>
                    </div>
                  </footer>
                </blockquote>
              </div>
            </div>
          </section>
          <section
            id="4"
            className={`${styles["relative"]} ${styles["py-12"]} ${styles["sm:py-20"]} ${styles["md:py-24"]} ${styles["lg:py-32"]}`}
            sectionname="cta"
            sectioncomponent="Cta"
            sortorder="3"
            data-v-3a9a377e=""
          >
            <div
              aria-hidden="true"
              className={`${styles["block"]}`}
              data-v-3a9a377e=""
            >
              <div
                className={`${styles["absolute"]} ${styles["inset-y-0"]} ${styles["left-0"]} ${styles["w-1/2"]} ${styles["bg-gray-50"]} ${styles["rounded-r-3xl"]} ${styles["mb-12"]}`}
                data-v-3a9a377e=""
              ></div>
              <svg
                className={`${styles["absolute"]} ${styles["-ml-3"]} ${styles["top-8"]} ${styles["left-1/2"]}`}
                width="404"
                height="392"
                fill="none"
                viewBox="0 0 404 392"
                data-v-3a9a377e=""
              >
                <defs data-v-3a9a377e="">
                  <pattern
                    id="8228f071-bcee-4ec8-905a-2a059a2cc4fb"
                    x="0"
                    y="0"
                    width="20"
                    height="20"
                    patternUnits="userSpaceOnUse"
                    data-v-3a9a377e=""
                  >
                    <rect
                      x="0"
                      y="0"
                      width="4"
                      height="4"
                      className={`${styles["text-gray-200"]}`}
                      fill="currentColor"
                      data-v-3a9a377e=""
                    ></rect>
                  </pattern>
                </defs>
                <rect
                  width="404"
                  height="392"
                  fill="url(#8228f071-bcee-4ec8-905a-2a059a2cc4fb)"
                  data-v-3a9a377e=""
                ></rect>
              </svg>
            </div>
            <div
              className={`${styles["max-w-md"]} ${styles["px-4"]} ${styles["mx-auto"]} ${styles["sm:max-w-3xl"]} ${styles["sm:px-6"]} ${styles["lg:max-w-7xl"]} ${styles["lg:px-8"]}`}
              data-v-3a9a377e=""
            >
              <div
                className={`${styles["relative"]} ${styles["px-6"]} ${styles["py-10"]} ${styles["overflow-hidden"]} ${styles["bg-white"]} ${styles["border-primary"]} ${styles["border-2"]} ${styles["shadow-xl"]} ${styles["rounded-2xl"]} ${styles["sm:px-12"]} ${styles["sm:py-20"]}`}
                data-v-3a9a377e=""
              >
                <div
                  aria-hidden="true"
                  className={`${styles["absolute"]} ${styles["inset-0"]} ${styles["-mt-72"]} ${styles["sm:-mt-32"]} ${styles["md:mt-0"]}`}
                  data-v-3a9a377e=""
                >
                  <svg
                    className={`${styles["absolute"]} ${styles["inset-0"]} ${styles["w-full"]} ${styles["h-full"]}`}
                    preserveAspectRatio="xMidYMid slice"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 1463 360"
                    data-v-3a9a377e=""
                  >
                    <path
                      className={`${styles["text-primary/5"]}`}
                      fill="currentColor"
                      d="M-82.673 72l1761.849 472.086-134.327 501.315-1761.85-472.086z"
                      data-v-3a9a377e=""
                    ></path>
                    <path
                      className={`${styles["text-primary/10"]}`}
                      fill="currentColor"
                      d="M-217.088 544.086L1544.761 72l134.327 501.316-1761.849 472.086z"
                      data-v-3a9a377e=""
                    ></path>
                  </svg>
                </div>
                <div className={`${styles["relative"]}`} data-v-3a9a377e="">
                  <div
                    className={`${styles["flex"]} ${styles["flex-wrap"]} ${styles["justify-center"]} ${styles["w-full"]} ${styles["mx-auto"]} ${styles["sm:max-w-3xl"]}`}
                    data-v-3a9a377e=""
                  >
                    {imgData.map((key, i) => {
                      return (
                        <img
                          id={i}
                          src={key.name}
                          alt="User ben"
                          className={`${styles["user-avatar"]}`}
                          data-v-3a9a377e=""
                        />
                      );
                    })}
                  </div>
                  <div
                    className={`${styles["mt-6"]} ${styles["sm:mt-12"]} ${styles["sm:text-center"]}`}
                    data-v-3a9a377e=""
                  >
                    <h2
                      className={`${styles["text-3xl"]} ${styles["font-black"]} ${styles["tracking-tight"]} ${styles["text-gray-900"]} ${styles["sm:text-4xl"]} ${styles["md:text-5xl"]} ${styles["md:leading-tight"]} ${styles["md:max-w-4xl"]} ${styles["md:mx-auto"]}`}
                      data-v-3a9a377e=""
                    >
                      Join our community of fans that love PropReg
                      <span
                        className={`${styles["text-primary"]}`}
                        data-v-3a9a377e=""
                      >
                        .
                      </span>
                    </h2>
                  </div>
                  <div
                    className={`${styles["mt-6"]} ${styles["sm:mt-12"]} ${styles["sm:mx-auto"]} ${styles["sm:max-w-lg"]} ${styles["flex"]} ${styles["flex-col"]} ${styles["items-center"]}`}
                    data-v-3a9a377e=""
                  ></div>
                  <ile-root id="ile-3" data-v-app="" hydrated="">
                    <form
                      className={`${styles["grid"]} ${styles["gap-2"]} ${styles["grid-cols-1"]} ${styles["sm:w-full"]} ${styles["sm:flex"]} ${styles["sm:items-center"]} ${styles["sm:flex-wrap"]} ${styles["mt-6"]} ${styles["sm:mt-12"]} ${styles["sm:mx-auto"]} ${styles["sm:max-w-lg"]}`}
                    >
                      <label
                        htmlFor="cta-email"
                        className={`${styles["sr-only"]}`}
                      >
                        Email address
                      </label>
                      <input
                        type="text"
                        id="email"
                        name="email"
                        onChange={handleInputChangeTwo}
                        className={`${styles["block"]} ${styles["w-full"]} ${styles["px-5"]} ${styles["py-3"]} ${styles["text-base"]} ${styles["text-gray-900"]} ${styles["placeholder-gray-500"]} ${styles["border"]} ${styles["border-gray-300"]} ${styles["rounded-md"]} ${styles["shadow-sm"]} ${styles["focus:border-primary"]} ${styles["focus:ring-primary"]} ${styles["focus-visible:ring-primary"]} ${styles["flex-1"]}`}
                        required={true}
                        placeholder="Enter your email..."
                      ></input>
                      <div>
                        <ModalComponent
                          title="code_secure>, a trustworthy innovation"
                          cancelButtonText="Cancel"
                          formData={formData}
                          actionButtonText="Submit"
                        />
                      </div>
                    </form>
                  </ile-root>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <ile-root id="ile-2" data-v-app="" hydrated=""></ile-root>
      <script></script>
    </div>
  );
};
export default Home;
