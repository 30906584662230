// Import necessary dependencies and styles
import axios from "axios";
import { useState } from "react";
import styles from './home.module.scss';
import { Modal, ModalHeader, ModalBody } from "reactstrap";

// Define a ModalComponent
const ModalComponent = (props: any) => {
  // Initialize state variables
  const [showPopup, setShowPopup] = useState(false);
  const [modal, setModal] = useState(false);

  // Destructure props
  const {
    title,
    children,
    formData,
    className
  } = props;

  // Toggle modal open or closed
  const toggle = () => setModal(!modal);

  // Handle form submission
  const handleSubmit = async (event: any) => {
    event.preventDefault();
    console.log(formData);
    try {
      if (Object.keys(formData).length !== 0) {
        console.log(formData);
        setModal(!modal);
        setShowPopup(true);

        // Start confetti animation
        setTimeout(() => {
          // @ts-ignore: Unreachable code error
          window.startConfetti();
        }, 1000);

        // Post form data to the specified API endpoint
        await axios.post('https://sendemail.badev.tools/sendEmail', formData)
          .then(response => {
            setModal(!modal)
            
            // Stop confetti animation
            setTimeout(() => {
              // @ts-ignore: Unreachable code error
              window.stopConfetti();
            }, 3000)
          })
          .catch((error: any) => {
            console.error(error);
            
            // Stop confetti animation
            setTimeout(() => {
              // @ts-ignore: Unreachable code error
              window.stopConfetti();
            }, 3000)
          });
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Define close button for the modal
  const closeBtn = (
    <button className="close" onClick={toggle}>
      &times;
    </button>
  );

  // Render the component
  return (
    <div>
      <button className={`${styles['ui-button']} ${styles['ui-button-base']} ${styles['ui-button-primary']}`} type="submit" onClick={handleSubmit}>Join our waitlist</button>
      <Modal isOpen={modal} toggle={toggle} className={className} style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
        <ModalHeader className=" border-0" toggle={toggle} close={closeBtn}>
          <b>{title}</b>
        </ModalHeader>
        <ModalBody className="text-left border-0">
          <p className="modal-label">Welcome onboard! We will keep you updated on all the exciting new developments and activities that are scheduled to take place. Keep an eye out!</p>
          {children}
        </ModalBody>
      </Modal>
    </div>
  );
};

// Export the ModalComponent
export default ModalComponent;